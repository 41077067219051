import React, { useContext, useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser'
import { Link, useHistory, Redirect } from 'react-router-dom';
import format from 'date-fns/format';
import LaunchIcon from '@mui/icons-material/Launch';
import IconButton from '@mui/material/IconButton';

import { permission_key_values, permission_key_values_claim } from '../../../utilities/permissions';
import { checkPermission } from '../../../utilities/commonUtilities';

import service from "./service";
import BillingInfo from './BillingInfo';
import ServiceLines from './ServiceLines';
import AdditionalClaimInfo from './AdditionalClaimInfo';

import Notify from '../../commons/notify';
import TextInput from '../../commons/input/input'
import WarningPopUp from '../../commons/warningPopUp';
import SelectInput from '../../commons/input/SelectInput';
import TextAreaInput from '../../commons/textarea/textarea';
import CalendarInput from '../../commons/input/CalendarInput';
import RightSidePanel from '../../commons/RightSidePanel/RightSidePanel';
import i18n from '../../../utilities/i18n';
import { getStorage, setStorage } from '../../../utilities/browserStorage';
import { AUTHORIZATION_REFERRAL, NOTES_CLAIM_TYPES } from '../../../utilities/dictionaryConstants';
import { ADD_ERROR, ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from '../../../utilities/labelConfigs';
import {
    TAB1, TAB2, TAB3, TAB4, CLAIM_INS_VALIDATION_PRIMARY_IDS,
    CLAIM_INS_VALIDATION_SECONDARY_IDS, CLAIM_INS_VALIDATION_TERTIARY_IDS, INSURANCE_PRIORITY_PRIMARY_VALUE,
    INSURANCE_PRIORITY_SECONDARY_VALUE, INSURANCE_PRIORITY_TERTIARY_VALUE, CLAIM_POS_IDS_VALIDATION,
    DEFAULT_DATE_FORMAT, ROUTE_CLAIM_ACTION_LOGS, ROUTE_PATIENTS_LEDGER, ROUTE_PAGE_LOCKS
} from '../../../utilities/staticConfigs';

import actionImg from '../../../assets/images/action.png';
import LoadingContext from '../../../container/loadingContext';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import PatientDropDownSearch from '../../patientManagement/patients/patientDropDownSearch';
import PatientsAdvancedSearch from '../../patientManagement/patients/PatientsAdvancedSearch';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { Form } from 'react-bootstrap';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getAllInformation, getClaimData, updateAuthIdentifierData, updateBasicData, updateProcedureData } from '../claimManagementSlice/claimManagementSlice';
import { addNewClaim, removeAllDetails, updateAuthIdentfier, updateBasicSaveData, updateProcedureSaveData } from '../claimManagementSlice/saveManagementSlice';
import CommonButton from '../../commons/Buttons';
import { removeResponsibility } from '../claimManagementSlice/claimSelectInputSlice';
const procedure = {
    id: '',
    patient_death_date: "",
    dos_from: "",
    dos_to: "",
    pos_id: "",
    pos_id_List: [],
    emg: 2,
    cpt_hcpcs: "",
    cpt_hcpcsList: [],
    m1_id: [],
    m2_id: "",
    m3_id: "",
    m4_id: "",
    m1List: [],
    m2List: [],
    m3List: [],
    m4List: [],
    dx_pointers: "",
    fee: "0.00",
    fee_units: "1",
    charges: "0.00",
    cpt_epsdt: 2,
    proced_description: "",
    line_note: "",
    drug_code: "",
    drug_unit_type: "",
    drug_unit_value: "",
    measurement_unit_type: "",
    measurement_unit_value: "",
    anesthesia: "",
    responsibility_type: "",
    payment_doneList: [],
    claim_status:"",
    claim_sub_status:""
};
const PRIMARY_STATUS_LIST = ["Send Electronic Claim to Primary", "Send Paper Claim to Primary"];
const SECONDARY_STATUS_LIST = ["Send Electronic Claim to Secondary", "Send Paper Claim to Secondary"];
const TERTIARY_STATUS_LIST = ["Send Electronic Claim to Tertiary", "Send Paper Claim to Tertiary"];

function ProfessionalClaims(props) {
    const dispatch = useDispatch();
    //getting claim common data from selector
    const loading = useSelector(state => state.claimData.loading);
    const claimInfoData = useSelector(state => state.claimData.claimInfos);
    const claimInfoDetails = useSelector(state => state.claimData.commonData);
    const { claimInitiState, claimStatusFound } = useSelector(state => state.claimData);
    const { infoLoaded, saveMode, commonData, patientSelectedData, selectPatientData } = useSelector(state => state.claimSaveManagmentData);
    const newClaimBillingProvider = useSelector(state => state.claimData.BillingInfoData.billing_provider);
    const savedClaimBillingProvider = useSelector(state => state.claimSaveManagmentData.BillingInfoData.billing_provider);

    const Mode_Of_Save = useSelector(state => state.claimSaveManagmentData.saveMode);
    const newClaimReferringProvider = useSelector(state => state.claimData.BillingInfoData.referring_provider);
    const savedClaimReferringProvider = useSelector(state => state.claimSaveManagmentData.BillingInfoData.referring_provider);
    const patientDeathDate = useSelector(state => state.claimSaveManagmentData.BillingInfoData.date_of_death);
    const claimSubStatusListFromRedux = useSelector(state => state.claimSelectInputData.claimSubStatusList)
    //form warings and status
    const formWaringData = useSelector(state => state.claimData.formWarnData);
    const formWarningStatuses = useSelector(state => state.claimData.formWarnData);
    //commons
    const history = useHistory();
    const [activeTab, setActiveTab] = useState(TAB1);
    const practicePK = getStorage('practice');
    const setShowLoadingBar = useContext(LoadingContext);
    const [accidentNoId, setAccidentNoId] = useState("");

    const [dataFromDbExist, setDataFromDbExist] = useState(false);
    // Api setting Arrays
    const [notesTypeList, setNotesTypeList] = useState([]);
    const [claimStatusList, setClaimStatusList] = useState([]);
    const [claimSubStatusList, setClaimSubStatusList] = useState([]);
    const [initialStatus, setInitialStatus] = useState(true);
    const [dbDataFlag, setDbDataFlag] = useState(true);
    const [dbAuthReferral, setDbAuthReferral] = useState('');
    const [hide, setHide] = useState(false);
    const [billingInfoDbData, setBillingInfoDbData] = useState({});
    const [billingInfo, setBillingInfo] = useState({});
    const [diagnosises, setDiagnosises] = useState({});
    const [diagnosisDbData, setDiagnosisDbData] = useState({});
    const [diagnosisesValidated, setDiagnosisesValidated] = useState(true);
    const [checkForDuplicate, setCheckForDuplicate] = useState(true);
    const [additionalInfoData, setAdditionalInfoData] = useState({});
    const [additionalInfoDataDb, setAdditionalInfoDataDb] = useState({});
    const [isSaved, setIsSaved] = useState(false);
    const [patientRemoved, setPatientRemoved] = useState(false);
    const [isCloseMode, setIsCloseMode] = useState(false);
    const [isSaveMode, setIsSaveMode] = useState(false);
    const [patientPK, setPatientPK] = useState("");
    const [selectPatient, setSelectPatient] = useState("");
    const [patientSelected, setPatientSelected] = useState([]);
    //claim note
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [header, setHeader] = useState("");
    const [claimNotesTextType, setClaimNotesTextType] = useState("2");
    const [claimNotesList, setClaimNotesList] = useState("");
    const [editClaimNotes, setEditClaimNotes] = useState(false);
    const [editClaimId, setEditClaimId] = useState(false);
    const [deleteClaimNoteModal, setDeleteClaimNoteModal] = useState(false);
    const [deleteClaimNoteMessage, setDeleteClaimNoteMessage] = useState("");
    const [deleteClaimNoteId, setDeleteClaimNoteId] = useState(false);
    //professionalclaims
    const [professionalClaimVariable, setProfessionalClaimVariable] = useState({
        "id": "", "pks": {
            "billing_info_pk": "", "diagnosises_pk": "", "procedures_pks": [], "additional_claim_info_pk": ""
        },
        "custom_claim_id": "", "reference_number": "", "posted_date": format(new Date(), 'yyyy-MM-dd'), "service_from_date": format(new Date(), 'yyyy-MM-dd'),
        "service_to_date": format(new Date(), 'yyyy-MM-dd'), "authorization_referral": "", "claim_type": 1, "patient_id": "", "patient_name": "",
        "claim_status": "", "claim_sub_status": "", "identifier": "", "patient": "",
        "locked_by_name": "", "claim_inactive": false, "claim_editable": true, "claim_locked": false, "active": true
    });
    const [claimNotesText, setClaimNotesText] = useState("");
    //
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    const [procedureRows, setProcedureRows] = useState([]);
    const [modifiersValidated, setModifiersValidated] = useState(true);

    const [claimDbActive, setClaimDbActive] = useState(false);
    const [inactiveClaimModal, setInactiveClaimModal] = useState(false);
    const [newAdded, setNewAdded] = useState(false);
    const [activeClaimModal, setActiveClaimModal] = useState(false);
    const [refreshProcedure, setRefreshProcedure] = useState(false);
    const [inactiveClaimAlertModal, setInactiveClaimAlertModal] = useState(false);
    const [billingInfoDataLoaded, setBillingInfoDataLoaded] = useState(false);
    const [saveConfirmation, setSaveConfirmation] = useState(false);
    const [saveCloseConfirmation, setSaveCloseConfirmation] = useState(false);

    const [warningMessage, setWarningMessage] = useState("");
    const [identifierData, setIdentifierData] = useState([]);

    const [claimClosePK, setClaimClosePK] = useState("");
    const [claimCloseID, setClaimCloseID] = useState("");
    const [claimStatusMasterId, setClaimStatusMasterId] = useState(0);
    const [openAlertModal, setOpenAlertModal] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [primaryInsuranceList, setPrimaryInsuranceList] = useState([]);
    const [secondaryInsuranceList, setSecondaryInsuranceList] = useState([]);
    const [tertiaryInsuranceList, setTertiaryInsuranceList] = useState([]);
    const [patientAdvSearchData, setPatientAdvSearchData] = useState([]);
    const [addNewRowClicked, setAddNewRowClicked] = useState(false);
    const [facilityPOS, setFacilityPOS] = useState({});
    const [gotoLedger, setGotoLedger] = useState(false);
    const [serviceLocationId, setServiceLocationId] = useState("");
    const [providerChanged, setProviderChanged] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isProcedureChangeFlag, setIsProcedureChangeFlag] = useState(false);
   
    useEffect(() => {
        if (props.claimPK) {
            getNewClaimData(props.claimPK);
            getNotesData();
            ListNewClaimNotes();
            if (props.activeSubTab)
                setActiveTab(props.activeSubTab)
        }
    }, [props.claimPK, props.activeTab]);

    useEffect(() => {
        if (providerChanged) {
            if (saveMode == 'Save') {
                dispatch(updateAuthIdentfier())
            }
            else {
                dispatch(updateAuthIdentifierData({ claimPK: claimInfoDetails?.id }))
            }
            setProviderChanged(false)
        }
    }, [providerChanged])
    
    useEffect(() => {
        if (isProcedureChangeFlag) {
            if (saveMode !== 'Save') {
                dispatch(updateProcedureData({ procedureArray: procedureRows, pk: claimInfoDetails?.pks?.diagnosises_pk }))
            }
            else {
                dispatch(updateProcedureSaveData({ procedureArray: procedureRows }))
            }
            setIsProcedureChangeFlag(false);
        }
    },[isProcedureChangeFlag])

    //common
    useEffect(() => {
        getClaimStatusData(0, 0);
        if (saveMode == 'SAVE') {
            getClaimSubStatusData(0, 0);
        }
    }, []);
    useEffect(() => {
        getClaimStatusData(0, 0);
    }, [patientPK]);
    //Warning Message changes --- start
    useEffect(() => {
        if (patientPK) {
            setProfessionalClaimVariable({
                ...professionalClaimVariable, "patient_id": patientPK, "patient_name": selectPatient, patient: patientPK
            })
            if (claimInitiState == 'Save' && !infoLoaded) {
                let data = {
                    "id": "", "pks": {
                        "billing_info_pk": "", "diagnosises_pk": "", "procedures_pks": [], "additional_claim_info_pk": ""
                    },
                    "custom_claim_id": "", "reference_number": "", "posted_date": format(new Date(), 'yyyy-MM-dd'), "service_from_date": format(new Date(), 'yyyy-MM-dd'),
                    "service_to_date": format(new Date(), 'yyyy-MM-dd'), "authorization_referral": "", "claim_type": 1,
                    "claim_status": "", "claim_sub_status": "", "identifier": "",
                    "locked_by_name": "", "claim_inactive": false, "claim_editable": true, "claim_locked": false, "active": true,
                    "patient_id": patientPK, "patient_name": selectPatient, patient: patientPK
                }

                dispatch(addNewClaim({ commonData: data, patientSelected: patientSelected, patientAdvSearchData: patientAdvSearchData, selectPatient: selectPatient }))
            }
        }
    }, [patientPK, selectPatient]);
    useEffect(() => {
        if (newAdded) {
            let item = {
                pk: 'add_new', claim_id: 'add_new',
                type: 'claims', action: 'remove', practice_pk: getStorage("practice")
            }
            props.addRemoveFromSelectedTab(item);
            props.setSelectedClaimType('')
            setNewAdded(false)
            setIsCloseMode(false)
            setIsSaveMode(false)
        }

    }, [newAdded]);

    let warningData = [
        {
            "Patient": ""
        }
    ];


    const [formWarningData, setFormWarningData] = useState(warningData);
    const [formWarningStatus, setFormWarningStatus] = useState(false);
    const checkFormWarning = (name, value) => {
        let formWarnData = formWarningData;
        if (name === 'selectPatient') {
            formWarnData[0]["Patient"] = value
        }
        setFormWarningData(formWarnData);
        setFormWarningStatus(!formWarningStatus);
    };

    useEffect(() => {
        setModifiersValidated(true);
        // dosFrom and dosTo is for updating min and max date against claim from procudre
        let dosFrom = '';
        let dosTo = '';
        let newDataTmp = [];
        newDataTmp = procedureRows;
        let M4 = true, M3 = true, M2 = true, M1 = true;
        if (Array.isArray(newDataTmp)) {
            newDataTmp.forEach((item) => {
                // CHECK VALUES IN dos_from, dos_to, pos_id, dx_pointers, AND IF ANY OF THEM ARE MISSING, SET PROCEDURES WARNING MESSAGE 
                if (item.dos_from && ('' + item.dos_from).trim().length && item.dos_to && ('' + item.dos_to).trim().length && item.pos_id && ('' + item.pos_id).trim().length && item.dx_pointers && ('' + item.dx_pointers).trim().length) {
                    // arrDataCount++;
                }
                // CHECK ORDER OF MODIFIERS DATA, 
                // e.g. IF M1 FILLED, M2 BLANK AND M3 OR M4 HAVING DATA, modifiersValidated SHOULD SET TO false - 2021Dec14
                M1 = (Array.isArray(item.m1_id) && item.m1_id.length) ? true : (Array.isArray(item.m1_id) && !item.m1_id.length) ? false : item.m1_id ? true : false;
                M2 = (Array.isArray(item.m2_id) && item.m2_id.length) ? true : (Array.isArray(item.m2_id) && !item.m2_id.length) ? false : item.m2_id ? true : false;
                M3 = (Array.isArray(item.m3_id) && item.m3_id.length) ? true : (Array.isArray(item.m3_id) && !item.m3_id.length) ? false : item.m3_id ? true : false;
                M4 = (Array.isArray(item.m4_id) && item.m4_id.length) ? true : (Array.isArray(item.m4_id) && !item.m4_id.length) ? false : item.m4_id ? true : false;

                if (M4 && (!M3 || !M2 || !M1)) {
                    setModifiersValidated(false);
                }
                else if (M3 && (!M2 || !M1)) {
                    setModifiersValidated(false);
                }
                else if (M2 && !M1) {
                    setModifiersValidated(false);
                }

                // checking dos from is min
                if (dosFrom) {
                    if ((item.dos_from) && (item.dos_from < dosFrom)) {
                        dosFrom = item.dos_from;
                    }
                } else {
                    dosFrom = item.dos_from;
                }
                // and dos to is max
                if (dosTo) {
                    if (item.dos_to && (item.dos_to > dosTo)) {
                        dosTo = item.dos_to;
                    }
                } else {
                    dosTo = item.dos_to;
                }
            });
        }
        setClaimDateOfService(dosFrom, dosTo);
        if (props.setProcedureRows) {
            props.setProcedureRows(procedureRows)
        }
    }, [procedureRows]);

    useEffect(() => {
        setFormWarningStatus(true);
    }, [formWarningStatus])

    //if there is claim status call claim substatus with status pk
    useEffect(() => {
        if (claimStatusFound) {
            getClaimStatusSubStatusData(0, 0, claimInfoDetails?.claim_status)
        }
    },[claimStatusFound])

    useEffect(() => {
        if (!initialStatus && claimSubStatusList && claimSubStatusList.length > 0) {
            if (saveMode !== 'Save') {
                dispatch(updateBasicData({ name: "claim_sub_status", value: claimSubStatusList[0].id, claimPK: claimInfoDetails?.id }))
            }
            else {
                dispatch(updateBasicSaveData({ name: "claim_sub_status", value: claimSubStatusList[0].id }))
            }
        }
    }, [claimSubStatusList])

    const onTabChange = (e, tab) => {
        if (professionalClaimVariable.custom_claim_id && props.claimPK) {
            let item = {
                pk: props.claimPK, claim_id: professionalClaimVariable.custom_claim_id,
                type: 'claims', action: 'add', practice_pk: getStorage("practice"),
                active_tab: tab
            }
            props.addRemoveFromSelectedTab(item);
        }
        setActiveTab(tab);
        if (tab !== 'tab2') {
            if (saveMode !== 'Save') {
                dispatch(updateProcedureData({ procedureArray: procedureRows, pk: claimInfoDetails?.pks?.diagnosises_pk }))
            }
            else {
                dispatch(updateProcedureSaveData({ procedureArray: procedureRows }))
            }
        }
    };
    //professionalclaim


    //Warning Message changes --- end
    const onHandleNewClaimDate = (selected, name) => {
        if (name === "posted_date") {
            setProfessionalClaimVariable({ ...professionalClaimVariable, "posted_date": selected });
        }
        else if (name === "service_from_date") {
            setProfessionalClaimVariable({ ...professionalClaimVariable, "service_from_date": selected, "service_to_date": selected });
        }
        else if (name === "service_to_date") {
            setProfessionalClaimVariable({ ...professionalClaimVariable, "service_to_date": selected });
        }
    };

    const onHandleChange = (e) => {
        if (e.target) {
            let name = e.target.name;
            let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
            if (name === 'claim_status') {
                getClaimStatusSubStatusData(0, 0, value)
                checkForClaimAlert(value)
            }
            /** IN-ORDER TO CHOOSE AUTH OR REFERRAL - THE CLAIM SHOULD HAVE A BILLING PROVIDER, REFERRING PROVIDER AND AN INSURANCE SELECTED */
            if (name === 'authorization_referral' && value > 0) {
                let provider;
                if (Mode_Of_Save === 'Save') {
                    provider = value === 1 ? savedClaimBillingProvider : savedClaimReferringProvider;

                } else {
                    provider = value === 1 ? newClaimBillingProvider : newClaimReferringProvider;
                }

                if ((value === 1 || value === 2) && !provider) {
                    showNotifyWindow("show", "error", i18n.t("errorMessages.auth_referral_error"), 5000);
                    return;
                }

                if (value > 0) {
                    setHide(true);
                }
            }
            checkFormWarning(name, value);
            if (saveMode !== 'Save') {
                dispatch(updateBasicData({ name: name, value: value, claimPK: claimInfoDetails?.id,claimStatusList:claimStatusList }))
            }
            else {
                dispatch(updateBasicSaveData({ name: name, value: value, claimStatusList: claimStatusList }))
            }
        }
    };

    function checkForClaimAlert(value) {
        let claimObj = claimStatusList.filter(item => {
            if (item.id === value)
                return item
        });
        if (claimObj && Array.isArray(claimObj) && claimObj.length > 0) {
            if (PRIMARY_STATUS_LIST.indexOf(claimObj[0].name) !== -1) {
                let primaryObj = primaryInsuranceList.filter(item => {
                    if (item.id === billingInfoDbData.primary_insurance)
                        return item
                });
                if (primaryObj && Array.isArray(primaryObj) && primaryObj.length > 0 && primaryObj[0].alert) {
                    setAlertMsg(primaryObj[0].alert);
                    setOpenAlertModal(true);
                }
            } else if (SECONDARY_STATUS_LIST.indexOf(claimObj[0].name) !== -1) {
                let secondaryObj = secondaryInsuranceList.filter(item => {
                    if (item.id === billingInfoDbData.secondary_insurance)
                        return item
                });
                if (secondaryObj && Array.isArray(secondaryObj) && secondaryObj.length > 0 && secondaryObj[0].alert) {
                    setAlertMsg(secondaryObj[0].alert);
                    setOpenAlertModal(true);
                }
            } else if (TERTIARY_STATUS_LIST.indexOf(claimObj[0].name) !== -1) {
                let tetiaryObj = tertiaryInsuranceList.filter(item => {
                    if (item.id === billingInfoDbData.tertiary_insurance)
                        return item
                });
                if (tetiaryObj && Array.isArray(tetiaryObj) && tetiaryObj.length > 0 && tetiaryObj[0].alert) {
                    setAlertMsg(tetiaryObj[0].alert);
                    setOpenAlertModal(true);
                }
            }

        }
    }

    function getNewClaimData() {
        if (claimInfoData?.length === 0 && props.claimPK) {
            dispatch(getClaimData(props.claimPK))
        }
        else {
            const exist = claimInfoData?.findIndex(item => item.claimPK === props.claimPK)
            if (exist >= 0) {
                dispatch(getAllInformation({ node: 'common_data', pk: props.claimPK }))
            }
            else {
                if (props.claimPK) {
                    dispatch(getClaimData(props.claimPK))
                }
            }
        }

    }

    /***
     * remove all information stored in redux on patient remove
     */
    useEffect(() => {
        if (patientRemoved == true) {
            dispatch(removeAllDetails(2))
            dispatch(removeResponsibility())
        }
    }, [patientRemoved])

    useEffect(async () => {
        if (saveMode === 'Save') {
            if (commonData) {
                await checkFormWarning('selectPatient', commonData?.patient_name);
                checkFormWarning('claimId', commonData?.custom_claim_id);
                setWarningFromServer(commonData);
                setDbAuthReferral(commonData?.authorization_referral);
                setProfessionalClaimVariable({
                    ...professionalClaimVariable,
                    patient_name: commonData?.patient_name,
                    id: commonData?.id,
                    patient: commonData?.patient,
                    pks: commonData?.pks,
                    locked_by_name: (commonData?.locked && commonData?.locked_by === JSON.parse(getStorage('userData'))?.profile_id) ? i18n.t('commons.self') : commonData?.locked_by_name,
                    claim_editable: (!commonData?.locked || (commonData.locked && commonData?.locked_by === JSON.parse(getStorage('userData'))?.profile_id && commonData?.unid === JSON.parse(getStorage('userData'))?.unid)) ? true : false,
                    claim_inactive: commonData?.active ? false : true,
                    active: commonData.active,
                    claim_locked: commonData?.locked && commonData?.locked_by !== JSON.parse(getStorage('userData'))?.profile_id && commonData?.unid !== JSON.parse(getStorage('userData'))?.unid ? true : false,
                    custom_claim_id: commonData?.custom_claim_id,
                    patient_id: commonData?.patient,
                    authorization_referral: commonData?.authorization_referral,
                    claim_status: commonData?.claim_status,
                    claim_sub_status: commonData?.claim_sub_status,
                    identifier: commonData.identifier,
                    identifier_name: commonData?.identifier_name,
                    reference_number: commonData?.reference_number,
                    posted_date: commonData?.posted_date,
                    service_from_date: commonData?.service_from_date ? commonData?.service_from_date : format(new Date(), 'yyyy-MM-dd'),
                    service_to_date: commonData?.service_to_date ? commonData?.service_to_date : format(new Date(), 'yyyy-MM-dd'),
                });
                if (commonData?.authorization_referral) {
                    setHide(true)
                }
                
                if (patientSelectedData.length > 0) {
                    setPatientSelected(patientSelectedData);
                    setPatientAdvSearchData(patientSelectedData);
                }
                if (selectPatientData) {
                    setSelectPatient(selectPatientData)
                }
            }
        }
    }, [commonData])

    useEffect(async () => {
        if (Object.keys(claimInfoDetails).length != 0 && saveMode !== 'Save') {
            await setDataFromDbExist(false)
            setPatientPK(claimInfoDetails?.patient);
            checkFormWarning('selectPatient', claimInfoDetails?.patient_name);
            checkFormWarning('claimId', claimInfoDetails?.custom_claim_id);
            setClaimDbActive(claimInfoDetails?.active);
            setWarningFromServer(claimInfoDetails);
            setDbAuthReferral(claimInfoDetails?.authorization_referral);
            setProfessionalClaimVariable({
                ...professionalClaimVariable,
                patient_name: claimInfoDetails?.patient_name,
                id: claimInfoDetails?.id,
                patient: claimInfoDetails?.patient,
                pks: claimInfoDetails?.pks,
                locked_by_name: (claimInfoDetails?.locked && claimInfoDetails?.locked_by === JSON.parse(getStorage('userData'))?.profile_id) ? i18n.t('commons.self') : claimInfoDetails?.locked_by_name,
                claim_editable: (!claimInfoDetails?.locked || (claimInfoDetails.locked && claimInfoDetails?.locked_by === JSON.parse(getStorage('userData'))?.profile_id && claimInfoDetails?.unid === JSON.parse(getStorage('userData'))?.unid)) ? true : false,
                claim_inactive: claimInfoDetails?.claim_inactive,
                active: claimInfoDetails.active,
                claim_locked: claimInfoDetails?.locked && claimInfoDetails?.locked_by !== JSON.parse(getStorage('userData'))?.profile_id && claimInfoDetails?.unid !== JSON.parse(getStorage('userData'))?.unid ? true : false,
                custom_claim_id: claimInfoDetails?.custom_claim_id,
                patient_id: claimInfoDetails?.patient,
                authorization_referral: claimInfoDetails?.authorization_referral,
                claim_status: claimInfoDetails?.claim_status,
                claim_sub_status: claimInfoDetails?.claim_sub_status,
                identifier: claimInfoDetails.identifier,
                identifier_name: claimInfoDetails?.identifier_name,
                reference_number: claimInfoDetails?.reference_number,
                posted_date: claimInfoDetails?.posted_date,
                service_from_date: claimInfoDetails?.service_from_date,
                service_to_date: claimInfoDetails?.service_to_date,

            });

            if (claimInfoDetails?.authorization_referral) {
                setHide(true)
            }
        }
    }, [claimInfoDetails])


    //showing loading bar
    useEffect(() => {
        setShowLoadingBar(loading)
    }, [loading])

    const getClaimStatusData = (pageSize) => {
        const pageNum = 0;
        const result = service.ListClaimStatus(pageSize, pageNum, practicePK, null, patientPK, props.claimPK);
        result.then(response => {
            setClaimStatusList(response?.data || []);
            if (!props.claimPK && response?.data) {
                const claimStatusId = response.data?.find((item) => item.name.toLowerCase() === "patient balance");
                professionalClaimVariable["claim_status"] = claimStatusId;
            }
        }).catch(() => {
            setClaimStatusList([]);
        })
    };

    const getClaimSubStatusData = (pageSize) => {
        const pageNum = 0;
        const result = service.ListClaimSubStatus(pageSize, pageNum, practicePK, props.claimPK);
        result.then(response => {
            setClaimSubStatusList(response.data);
            setInitialStatus(false);
        });
    };

    const getClaimStatusSubStatusData = (pageSize, page, statusPK) => {
        if (statusPK) {
            const result = service.ListClaimStatusSubStatus(pageSize, page, practicePK, props.claimPK, statusPK);
            result.then(response => {
                setClaimSubStatusList(response.data);
            });
        }
        else {
            setClaimSubStatusList([]);
        }

    };

    function onInactiveClaim(e) {
        if (professionalClaimVariable.patient_id) {
            e.preventDefault();
            setInactiveClaimModal(true);
        }
        else {
            showNotifyWindow("show", "error", i18n.t("errorMessages.patient_not_selected"));
        }
    }

    function savingDataForProfessionalClaim() {
        let filteredProfessionalClaimDataVariable = {}
        let data = {};
        const { patient_id, reference_number, posted_date, service_from_date,
            service_to_date, claim_status, claim_sub_status, authorization_referral,
            claim_type, identifier } = professionalClaimVariable;
        const active = !professionalClaimVariable.claim_inactive;
        const professionalClaimVariableData = {
            patient: patient_id, reference_number, posted_date, service_from_date,
            service_to_date, claim_status, claim_sub_status, authorization_referral, claim_type,
            identifier, active
        }
        for (const [key, value] of Object.entries(professionalClaimVariableData)) {
            if (value && key.endsWith("_date")) {
                filteredProfessionalClaimDataVariable[key] = value
            } else {
                filteredProfessionalClaimDataVariable[key] = value;
            }
        }
        data = { ...filteredProfessionalClaimDataVariable }
        return data;

    }

    useEffect(() => {
        if (professionalClaimVariable.claim_status && claimStatusList && Array.isArray(claimStatusList)) {
            let objTemp = claimStatusList.find(element => parseInt(element.id, 10) == parseInt(professionalClaimVariable.claim_status, 10));
            setClaimStatusMasterId(objTemp && objTemp.master_status ? objTemp.master_status : 0);
        }
        else {
            setClaimStatusMasterId(0);
        }
    }, [professionalClaimVariable.claim_status]);

    useEffect(() => {
        if (props.setCommonDetails) {
            props.setCommonDetails(professionalClaimVariable)
        }
    }, [professionalClaimVariable])

    function onSaveFormData(e, isClose = false) { 

        for (let procedureData of procedureRows) {
            if (!procedureData.claim_status){
                return
            }
            if (procedureData.claim_status && (claimSubStatusListFromRedux?.filter(item => item.parent_claim_status_ids?.includes(procedureData?.claim_status)).length > 0 ) && !procedureData.claim_sub_status ) {
                showNotifyWindow("show", "error", "please choose claim sub status before saving")
                return;
            }
        }
        const currentDate = new Date();
        if (professionalClaimVariable.service_from_date > format(currentDate, 'yyyy-MM-dd') ){
            return;
        }
        if (professionalClaimVariable.posted_date) {
            const hasInvalidDate = procedureRows.some((pRowItem) =>
                pRowItem.dos_from > patientDeathDate || pRowItem.dos_to > patientDeathDate
            );

            if (hasInvalidDate) {
                showNotifyWindow("show", "error", i18n.t("errorMessages.dodError"));
                return;
            }
        }
        e.preventDefault();
        setInactiveClaimModal(false);
        let hospDateValidated = true;
        let isSaveStatus = false;
        if (!professionalClaimVariable.patient_id) return;

        let tmpAdnInfoHospDate = !props.claimPK ? additionalInfoData.hospitalization_from_date : additionalInfoDataDb.hospitalization_from_date;
        // Hospitalization Date should be prior than or = DOS When POS code is 21/51/61
        if (tmpAdnInfoHospDate && Array.isArray(procedureRows)) {
            procedureRows.forEach((pRowItem) => {
                if (pRowItem.dos_from && CLAIM_POS_IDS_VALIDATION.includes(pRowItem.pos_id) && new Date(tmpAdnInfoHospDate) > new Date(pRowItem.dos_from)) {
                    hospDateValidated = false;
                    showNotifyWindow("show", "error", i18n.t("errorMessages.claim_pos_date_error"));
                    return;
                }
            });
        }

        let arrPayerInfo = [];
        if (!props.claimPK && billingInfo.payer_info) arrPayerInfo = billingInfo.payer_info;
        else if (props.claimPK && billingInfoDbData.payer_info) arrPayerInfo = billingInfoDbData.payer_info;

        // CLAIM STATUS MATCH VALUES IN CLAIM_INS_VALIDATION_PRIMARY_IDS ARRAY, THEN PRIMARY INSURANCE DATA MANDATORY 
        if (claimStatusMasterId && CLAIM_INS_VALIDATION_PRIMARY_IDS.includes(claimStatusMasterId)) {
            if (!arrPayerInfo || !arrPayerInfo.length || !arrPayerInfo.find(element => (element.policy_id.trim() && parseInt(element.priority, 10) == parseInt(INSURANCE_PRIORITY_PRIMARY_VALUE, 10)))) {
                showNotifyWindow("show", "error", i18n.t("errorMessages.primary_insurance_validation"));
                return;
            }
        }

        if (claimStatusMasterId && CLAIM_INS_VALIDATION_SECONDARY_IDS.includes(claimStatusMasterId)) {
            // CLAIM STATUS MATCH VALUES IN CLAIM_INS_VALIDATION_SECONDARY_IDS ARRAY, THEN PRIMARY AND SECONDARY INSURANCES DATA MANDATORY 
            if (!arrPayerInfo || !arrPayerInfo.length
                || !arrPayerInfo.find(element => (element.policy_id.trim() && parseInt(element.priority, 10) == parseInt(INSURANCE_PRIORITY_PRIMARY_VALUE, 10)))
                || !arrPayerInfo.find(element => (element.policy_id.trim() && parseInt(element.priority, 10) == parseInt(INSURANCE_PRIORITY_SECONDARY_VALUE, 10)))) {
                showNotifyWindow("show", "error", i18n.t("errorMessages.secondary_insurance_validation"));
                return;
            }
        }

        if (claimStatusMasterId && CLAIM_INS_VALIDATION_TERTIARY_IDS.includes(claimStatusMasterId)) {
            // CLAIM STATUS MATCH VALUES IN CLAIM_INS_VALIDATION_TERTIARY_IDS ARRAY, THEN PRIMARY, SECONDARY AND TERTIARY INSURANCES  DATA ARE MANDATORY
            if (!arrPayerInfo || !arrPayerInfo.length
                || !arrPayerInfo.find(element => (element.policy_id.trim() && parseInt(element.priority, 10) == parseInt(INSURANCE_PRIORITY_PRIMARY_VALUE, 10)))
                || !arrPayerInfo.find(element => (element.policy_id.trim() && parseInt(element.priority, 10) == parseInt(INSURANCE_PRIORITY_SECONDARY_VALUE, 10)))
                || !arrPayerInfo.find(element => (element.policy_id.trim() && parseInt(element.priority, 10) == parseInt(INSURANCE_PRIORITY_TERTIARY_VALUE, 10)))) {
                showNotifyWindow("show", "error", i18n.t("errorMessages.tertiary_insurance_validation"));
                return;
            }
        }
        //checkForDuplicate
        if (!checkForDuplicate) {
            showNotifyWindow("show", "error", i18n.t("errorMessages.duplicateDiagnosis"));
            return; 
        }
        if (!diagnosisesValidated) {
            // IF DIAGNOSIS CODES NOT ADDED IN ORDER, IT WILL NOT ALLOW TO SAVE
            // e.g. IF diagnosis1 FILLED, diagnosis2 BLANK, AND ANY diagnosis3 TO diagnosis12 FILLED, IT WILL GIVE ERROR 
            showNotifyWindow("show", "error", i18n.t("errorMessages.invalid_diagonsis_order"));
            return;
        }

        if (!modifiersValidated) {
            // IF MODIFIERS NOT ADDED IN ORDER, IT WILL NOT ALLOW TO SAVE
            // e.g. IF M1 FILLED, M2 BLANK, AND M3 OR M4 FILLED, IT WILL GIVE ERROR 
            showNotifyWindow("show", "error", i18n.t("errorMessages.invalid_modifiers"));
            return;
        }
        if (billingInfoDbData?.payer_info && billingInfoDbData?.payer_info?.length == 0) {
            if (primaryInsuranceList.length > 0 || secondaryInsuranceList.length > 0 || tertiaryInsuranceList.length > 0) {
                showNotifyWindow("show", "error", i18n.t("errorMessages.payer_info_error"));
                return;
            }
        }

        if (professionalClaimVariable.authorization_referral && !professionalClaimVariable.identifier) {
            // IF AUTHORIZATION/REFERAL SELECTED BUT IDENTIFIER IS BLANK, IT WILL NOT ALLOW TO SAVE
            // showNotifyWindow("show", "error", i18n.t("errorMessages.invalid_modifiers"));
            return;
        }
        // if accident other than no then accident date is mandatory
        if (!professionalClaimVariable.pks.additional_claim_info_pk) {
            if (additionalInfoData.accident_id && additionalInfoData.accident_id != accidentNoId) {
                let accidentDate = Object.keys(additionalInfoData).filter((item) => item === "accident_date");
                if (accidentDate.length === 0) {
                    return
                }
            }
        }
        if (professionalClaimVariable.pks.additional_claim_info_pk) {
            if (additionalInfoDataDb.accident_id && additionalInfoDataDb.accident_id !== accidentNoId) {
                let accidentDate = Object.keys(additionalInfoDataDb).filter((item) => item === "accident_date");
                if (accidentDate.length === 0) {
                    return
                }
            }
        }

// Validate if cpt & does from & to are exists in every procedure row
    const isCptAndDosExistsInEveryRows = checkCptsAndDosExistsInProcedure(procedureRows);
      if (isCptAndDosExistsInEveryRows?.errors?.length) {
        const errorjoins = isCptAndDosExistsInEveryRows.errors.join(', '); 
          showNotifyWindow("show", "error", `Either DOS or CPTs are missing in the procedure rows ${errorjoins}`)
          return;
     }


        let result = "";
        let data = {};

        if (!hospDateValidated) {
            return;
        }

        if (!props.claimPK) {
            if (additionalInfoData.pk)
                delete additionalInfoData['pk']
            let procedures = filteringProcedureData();
           
            // filteringProcedureData should call before savingDataForProfessionalClaim
            // other wise claim dos not proper update
            let professionalclaim = savingDataForProfessionalClaim();
           
            data = {
                ...professionalclaim,
                "billing_info": billingInfo,
                "diagnosises": diagnosises,
                "additional_claim_info": additionalInfoData,
                "procedures": procedures
            }
        } else if (props.claimPK) {
           
            let procedures = filteringProcedureData();
           
            // filteringProcedureData should call before savingDataForProfessionalClaim
            // other wise claim dos not proper update
            let professionalclaim = savingDataForProfessionalClaim();
            // billingInfoDbData["pk"] = professionalClaimVariable.pks.billing_info_pk;
            // additionalInfoDataDb["pk"] = professionalClaimVariable.pks.additional_claim_info_pk;
            data = {
                ...professionalclaim,
                "billing_info": billingInfoDbData,
                "diagnosises": diagnosisDbData,
                "procedures": procedures,
                "additional_claim_info": additionalInfoDataDb
            }

        }
        if (!hospDateValidated) {
            return;
        }

        if (!props.claimPK) {
            result = service.SaveNewClaimCopy(data);
        } else {
            result = service.UpdateNewClaim(props.claimPK, data);
        }
        setShowLoadingBar(true);
        result.then(async (response) => {
            let warningText = "";
            if (response.status === 201 || response.status === 200) {
                setRefreshProcedure(true);
                setIsSaved(true);
                setClaimClosePK(response.data.id);
                setClaimCloseID(response.data.custom_claim_id);
                if (response.status === 201) {
                    showNotifyWindow("show", "success", ADD_SUCCESS);
                    setIsCloseMode(isClose);
                    setIsSaveMode(true)
                    isSaveStatus = true;
                }
                else {
                    if (professionalClaimVariable.claim_inactive) {
                        let item = {
                            pk: response.data.id, claim_id: response.data.custom_claim_id,
                            type: 'claims', action: 'remove', practice_pk: getStorage("practice"),
                            active_tab: activeTab
                        }
                        props.addRemoveFromSelectedTab(item);
                    }
                    showNotifyWindow("show", "success", UPDATE_SUCCESS)
                }
                if (response.data.claim_validations &&
                    response.data.claim_validations.length > 0) {
                    response.data.claim_validations.map((item) => {
                        let lbl = Array.isArray(item) ? item[0] : item;
                        let l_text = "<div><span style='width: 40px; display: inline-block; text-align: right;'>" + (lbl) + ".&nbsp;&nbsp;</span><span>" + i18n.t("warning.claim." + lbl) + "</div>";
                        if (Array.isArray(item)) {
                            l_text = l_text.replace("{" + Object.keys(item[1])[0] + "}", Object.values(item[1])[0]);
                        }
                        warningText = warningText + l_text
                    })
                    professionalClaimVariable['claim_status'] = response.data.claim_status.status;
                    getClaimStatusSubStatusData(0, 0, response.data.claim_status.status);
                    professionalClaimVariable['claim_sub_status'] = response.data.claim_status.sub_status;
                    setProfessionalClaimVariable({
                        ...professionalClaimVariable,
                        'claim_status': response.data.claim_status.status, 'claim_sub_status': response.data.claim_status.sub_status
                    });
                }
                setWarningFromServer(response);
            } else if (response.status === 405) {
                if (response.data.message === 'claim_inactive')
                    setInactiveClaimAlertModal(true);
                else if (response.data.message === 'claim_locked')
                    showNotifyWindow("show", "error", i18n.t("errorMessages.claim_locked"));
            } else if (response.status == 400) {
                if (response.data?.duplicate_policy_id)
                    showNotifyWindow("show", "error", i18n.t("errorMessages.duplicate_policy_id"));
                if (response.data?.duplicate_insurance)
                    showNotifyWindow("show", "error", i18n.t("errorMessages.duplicate_insurance"));
                if (response.data?.message === 'duplicate_modifier')
                    showNotifyWindow("show", "error", i18n.t("errorMessages.duplicate_modifier"));
                if (response.data?.message === 'unique_procedure')
                    showNotifyWindow("show", "error", i18n.t("errorMessages.unique_procedure"));
                if (response.data?.message === 'service_location_not_found')
                    showNotifyWindow("show", "error", i18n.t("errorMessages.service_location_error"));
                if (response.data?.message === 'invalid_responsibility_type') {
                    showNotifyWindow("show", "error", `Claim status selected not matching with the Priority on Procedure ${response.data?.data?.cpt ?? ""}`);
                }
                if (response.data?.message === 'cpt_or_claim_status_missing') {
                    showNotifyWindow("show", "error", `Procedure or the claim status is missing in a procedure row.`);
                }
            } else {
                showNotifyWindow("show", "error", ADD_ERROR);
            }
            setInactiveClaimModal(false);
            setWarningMessage(warningText);
            if (professionalClaimVariable.authorization_referral)
                getIdentifierData();
            if (warningText) {
                if (isClose) {
                    setSaveCloseConfirmation(true);
                } else {
                    setSaveConfirmation(true);
                }
            } else if (isClose) {
                let claim_id = ""
                let claim_pk = ""
                if (response.data) {
                    claim_pk = response.data.id;
                    claim_id = response.data.custom_claim_id;
                } else if (props.claimPK) {
                    claim_pk = props.claimPK;
                    claim_id = props.claimID;
                }
                if (claim_pk) {
                    let item = {
                        pk: claim_pk, claim_id: claim_id,
                        type: 'claims', action: 'remove', practice_pk: getStorage("practice"),
                        active_tab: activeTab
                    }
                    if (isSaveStatus) {
                        item = {
                            ...item,
                            action: 'add',
                            isClosed: true
                        }
                    }
                    props.addRemoveFromSelectedTab(item);
                    setNewAdded(true);
                }

            }
            else {
                let claim_id = ""
                let claim_pk = ""
                if (response.data) {
                    claim_pk = response.data.id;
                    claim_id = response.data.custom_claim_id;
                } else if (props.claimPK) {
                    claim_pk = props.claimPK;
                    claim_id = props.claimID;
                }
                if (claim_pk) {
                    // First hitting the api to close "add_new" tab
                    await closeAddNewClaimTabAfterSave();
                    // once it is executed then call the api to open the newly saved claim
                    let payloadToOpenNewlySavedClaim = {
                        pk: claim_pk, claim_id: claim_id,
                        type: 'claims', action: 'add', practice_pk: getStorage("practice"),
                        active_tab: activeTab
                    }
                    await props.addRemoveFromSelectedTab(payloadToOpenNewlySavedClaim);
                } 
            }
        }).then(() => {
            setShowLoadingBar(false);
        })
    }

    function setWarningFromServer(response) {
        let warningsArray = formWarningData;
        warningsArray = warningsArray.filter(function (obj) {
            return obj.type !== 'validation';
        });
        if (response?.claim_validations && response?.claim_validations?.length > 0) {
            let claimValidations = response?.claim_validations;
            claimValidations.forEach((item) => {
                let warningExist = warningsArray.filter((warningObj) => warningObj.tabName === item);
                let warningCode = '';
                let warningMessages = '';
                if (Array.isArray(item)) {
                    warningCode = item[0];
                    let optionString = i18n.t('warning.claim.' + warningCode);
                    warningMessages = optionString.replace(/{(\w+)}/g, function (_, k) {
                        return item[1][k];
                    });
                } else {
                    warningCode = item;
                    warningMessages = i18n.t('warning.claim.' + warningCode);
                }

                let warnings = { "tabName": warningCode, "type": "validation" }
                warnings[warningMessages] = "";
                if (warningExist) {
                    let warningIndexItem = '';
                    if (Array.isArray(item))
                        warningIndexItem = item[0];
                    else
                        warningIndexItem = item;
                    let warningIndex = warningsArray.findIndex(items => items.tabName === warningIndexItem);
                    if (warningIndex < 0) {
                        warningsArray.push(warnings);
                    } else {
                        warningsArray[warningIndex] = warnings;
                    }
                } else {
                    warningsArray.push(warnings);
                }
            })
        }
        setFormWarningData(warningsArray);
        if (formWarningStatus) {
            // should be set false and true
            setFormWarningStatus(false);
            setFormWarningStatus(true);
        }
        else {
            // should be set true and false
            setFormWarningStatus(true);
            setFormWarningStatus(false);
        }
    }

    function onHide() {
        if (!professionalClaimVariable.claim_inactive) { // if cancel button clicked then continue the inactive status
            dispatch(updateBasicData({ name: "claim_inactive", value: true, claimPK: claimInfoDetails?.id }))
        }
        setClaimNotesTextType('2');
    }
    function resetForm() {
        setClaimNotesText("");
        setHeader("");
        setEditClaimId("");
        setEditClaimNotes(false);
    }
    
    function addNewClaimNotes() {
        if(checkPermission(permission_key_values_claim.can_add_patient_claim_notes)){ 
            if (!props.claimPK) {
                return null;
            } else if (props.claimPK) {
                resetForm();
                setHeader(i18n.t('searchClaims.professionalClaims.addClaimNote'));
                setShowModalWindow(true);
            }
        } else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
        }
    }

    const getNotesData = () => {
        setNotesTypeList(NOTES_CLAIM_TYPES);
    };
    function onEditClaimNote(id) {
        if (checkPermission(permission_key_values_claim.search_claims_sub_module_modify)) {
            let result = service.GetClaimNotes(id);
            result.then((response) => {
                setHeader(i18n.t('searchClaims.professionalClaims.editClaimNote'));
                setShowModalWindow(true);
                setClaimNotesText(response.data.notes);
                setEditClaimNotes(true);
                setEditClaimId(response.data.id);
            });
        }
        else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
        }
    }
    function onDeleteClaimNote(id) {
        if (checkPermission(permission_key_values_claim.search_claims_sub_module_modify)) {
            claimNotesList.map((item) => {
                if (item.id === parseInt(id)) {
                    setDeleteClaimNoteMessage(item.notes);
                    setDeleteClaimNoteModal(true);
                    setDeleteClaimNoteId(item.id);
                }
                return null;
            })
        }
        else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
        }
    }
    function DeleteClaimNote() {
        let result = service.DeleteClaimNotes(deleteClaimNoteId);
        result.then(() => {
            setDeleteClaimNoteModal(false);
            setDeleteClaimNoteMessage("");
            showNotifyWindow("show", "success", DELETE_SUCCESS);
            ListNewClaimNotes();
        });
    }

    async function onSaveClaimNotes(e) {
        e.target.disabled = true;
        setShowLoadingBar(true);
        if (isSaving) {
            e.target.disabled = false;
            return;
        }
        try {
            setIsSaving(true);
            e.preventDefault();
            let result = "";
            let data = "";
            if (props.claimPK && claimNotesTextType && claimNotesText.trim()) {
                setShowModalWindow(true);
                data = { "claim": props.claimPK, "notes_type": claimNotesTextType, "notes": claimNotesText, "category": 'claim' };
                if (!editClaimNotes) result = await service.SaveNewClaimNotes(data);
                else if (editClaimNotes) result = await service.UpdateClaimNotes(editClaimId, data);

                resetForm();
                ListNewClaimNotes();
                if (result.status === 201 || result.status === 200) {
                    if (result.status === 201) showNotifyWindow("show", "success", ADD_SUCCESS);
                    else showNotifyWindow("show", "success", UPDATE_SUCCESS);
                } else {
                    showNotifyWindow("show", "error", ADD_ERROR);
                }
                setShowModalWindow(false);
                setIsSaving(false);
                setShowLoadingBar(false);
            }
        }
        catch (error) {
            showNotifyWindow("show", "error", ADD_ERROR);
            setShowLoadingBar(false);
        }
    }
    
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function ListNewClaimNotes() {
        let result = service.ListClaimNotes(props.claimPK, 0, 0);
        result.then((response) => {
            setClaimNotesList(response.data);
        });
    }

    function onShowActionButtonsClaim(event, rowId) {
        let dropdown = document.getElementById('dropdown-' + rowId);
        let dropdownMenu = document.getElementById('dropdown-menu-' + rowId);
        dropdown.classList.toggle('show');
        dropdownMenu.classList.toggle('show');
    }
    function onHideActionButtonsClaim(event, rowId) {
        let dropdown = document.getElementById('dropdown-' + rowId);
        let dropdownMenu = document.getElementById('dropdown-menu-' + rowId);
        dropdown.classList.remove('show');
        dropdownMenu.classList.remove('show');
    }


    function setCommonDXPointers(dxpValue, isDiagnosis) {
        if (isDiagnosis) {
            let newData = [];
            newData = procedureRows;
            if (Array.isArray(newData)) {
                const newArray = [];
                newData.forEach((element) => {
                    const item = JSON.parse(JSON.stringify(element))
                    item.dx_pointers = dxpValue ? dxpValue : "";
                    newArray.push(item);
                })
                setProcedureRows(newArray);
                if (saveMode !== 'Save') {
                    dispatch(updateProcedureData({ procedureArray: newArray, pk: claimInfoDetails?.pks?.diagnosises_pk }))
                }
                else {
                    dispatch(updateProcedureSaveData({ procedureArray: newArray }))
                }
            }
        }
    }

    const formatDrugCode = (rawValue) => {
        let formattedValue = '';
        for (let i = 0; i < rawValue?.length; i++) {
            if (i === 5 || i === 9) {
                formattedValue += '-';
            }
            formattedValue += rawValue.charAt(i);
        }
        return formattedValue;
    };

    

    function filteringProcedureData() {
        const newData = procedureRows;
        const newArray = [];
        // dosFrom and dosTo is set for updating claim dos while saving, 
        // if one procedure is added but any value not updated against it. so that
        // procedure will not save to db. for those case we need to iterate newArray
        // for final claim dos from and to
        let dosFrom = '';
        let dosTo = '';
        if (procedureRows.length === 1) {
            let firstItem = JSON.stringify(procedureRows[0]);
            let tempProcedure = JSON.stringify(procedure);
            if (firstItem === tempProcedure) {
                setClaimDateOfService(dosFrom, dosTo);
                return newArray;
            }
        }
        newData.forEach((element) => {
            let item = JSON.parse(JSON.stringify(element))
            if (item.id || item.dos_from || item.dos_to || item.pos_id || item.cpt_hcpcs || item.charges) {
                if (item.dos_from) item.dos_from = format(new Date(item.dos_from && typeof item.dos_from === 'string' ? item.dos_from + " 00:00:00" : item.dos_from), 'yyyy-MM-dd');
                if (item.dos_to) item.dos_to = format(new Date(item.dos_to && typeof item.dos_to === 'string' ? item.dos_to + " 00:00:00" : item.dos_to), 'yyyy-MM-dd');
                if(item.drug_code) {
                    item.drug_code = formatDrugCode(item.drug_code)
                }
                for (const [key, value] of Object.entries(item)) {
                    if (!value && key != 'dx_pointers') {
                        delete item[key];
                    }
                    if (key.endsWith("List")) {
                        delete item[key];
                    }
                }
                newArray.push(item);
            }
        })
        newArray.forEach((item) => {
            if (!item.pos_id && !item.cpt_hcpcs && !item.dx_pointers) {
                return;
            }
            // checking dos from is min
            if (dosFrom) {
                if ((item.dos_from) && (item.dos_from < dosFrom)) {
                    dosFrom = item.dos_from;
                }
            } else {
                dosFrom = item.dos_from;
            }
            // and dos to is max
            if (dosTo) {
                if (item.dos_to && (item.dos_to > dosTo)) {
                    dosTo = item.dos_to;
                }
            } else {
                dosTo = item.dos_to;
            }

        });
        setClaimDateOfService(dosFrom, dosTo);
        return newArray;
    }

    function ActivateClaim() {
        const active_result = service.UpdateClaimActive(props.claimPK, { 'active': !professionalClaimVariable.claim_inactive });
        active_result.then(response => {
            setActiveClaimModal(false);
            let item = {
                pk: response.data.id, claim_id: response.data.custom_claim_id,
                type: 'claims', action: 'remove', practice_pk: getStorage("practice"),
                active_tab: activeTab
            }
            props.addRemoveFromSelectedTab(item);
        })
    }

    function onAlertOk() {
        setInactiveClaimAlertModal(false);
    }

    /**
     * @description Util function execute api call to close the "New Professional Claim"
     * @returns null
     */
    async function closeAddNewClaimTabAfterSave() {
        let payloadForCloseAddNewTab = {
            pk: 'add_new', claim_id: 'add_new',
            type: 'claims', action: 'remove', practice_pk: getStorage("practice")
        }
        // await is needed here to make sure the the second api will be executed only after this response is received 
        await props.addRemoveFromSelectedTab(payloadForCloseAddNewTab);
        props.setSelectedClaimType('')
        setNewAdded(false)
        setIsCloseMode(false)
        setIsSaveMode(false)
        return
    }

    /**
     * @description This function is triggered when user click on "OK" in the warning dialogue box that pops up when a claim create and update occurs.
     */
    async function onSaveHide() {
        try {
            // Update the states to hide/close the dialogue box
            setSaveConfirmation(false);
            setSaveCloseConfirmation(false);

            // And if this being triggering from a new claim then need to call user-oped-tab api calls to close the "add_new" tab and then to open the just saved claim tab.
            // hence, first calling the api to close the "add_new" tab and only once get api response of the same, then,
            // calling second api to open the claim tab for the just saved new claim.
            if (claimClosePK && isSaveMode) {
                await closeAddNewClaimTabAfterSave();

                let payloadToOpenNewlySavedClaim = {
                    pk: claimClosePK, claim_id: claimCloseID,
                    type: 'claims', action: 'add', practice_pk: getStorage("practice"),
                    active_tab: activeTab
                }
                await props.addRemoveFromSelectedTab(payloadToOpenNewlySavedClaim);
            }
        } catch (error) {
            console.error(`Error occurred from: ${arguments?.callee?.name}`, error);
        }
    }


    function getIdentifierData() {
        let provider;
        if (Mode_Of_Save === 'Save') {
            provider = professionalClaimVariable.authorization_referral === 1 ? savedClaimBillingProvider : savedClaimReferringProvider;
        } else {
            provider = professionalClaimVariable.authorization_referral === 1 ? newClaimBillingProvider : newClaimReferringProvider;
        }

        let patient = Mode_Of_Save == 'Save' ? commonData?.patient : claimInfoDetails?.patient;
        const result = service.GetIdentifiers(patient,
            professionalClaimVariable.authorization_referral, provider);
        result.then(response => {
            setIdentifierData(response.data);
            setDbDataFlag(false);
        })
    }
    useEffect(() => {
        if (!professionalClaimVariable?.claim_inactive && !professionalClaimVariable.active)

            setActiveClaimModal(true);

    }, [professionalClaimVariable.claim_inactive])

    useEffect(() => {
        if (professionalClaimVariable.authorization_referral)
            getIdentifierData();
        else setIdentifierData([]);
    }, [professionalClaimVariable.authorization_referral])
    useEffect(() => {
        if (dbAuthReferral &&
            professionalClaimVariable.authorization_referral === dbAuthReferral
            && professionalClaimVariable.identifier
            && !dbDataFlag) {
            const identifier = identifierData.filter(
                (item) => item.id === professionalClaimVariable.identifier
            )
            if (identifier.length === 0) {
                let temp_identifierData = identifierData;
                temp_identifierData.push({
                    'id': professionalClaimVariable.identifier,
                    'name': professionalClaimVariable.identifier_name
                });
                setIdentifierData(temp_identifierData);
                setDbDataFlag(true);
            }
        }
    }, [dbDataFlag])

    useEffect(() => {
        // set procedure pos based on selected facility location from billing info 
        if (billingInfoDbData.service_location && billingInfoDbData.service_location.location_type === 'facility') {
            const result = service.FacilityPOS(billingInfoDbData.service_location.id);
            result.then(response => {
                let posData = response?.data;
                if (posData?.pos_id) {
                    setFacilityPOS({
                        'id': posData.pos_id,
                        'name': posData.pos_name,
                        'label': posData.pos_name
                    });
                } else {
                    setFacilityPOS({});
                }
            }).catch(() => {
                setFacilityPOS({}); 
            })
        } else {
            setFacilityPOS({});
        }
    }, [billingInfoDbData.service_location])

    function onSaveCloseHide() {
        setSaveCloseConfirmation(false);
        if (claimClosePK) {
            let item = {
                pk: claimClosePK, claim_id: claimCloseID,
                type: 'claims', action: 'remove', practice_pk: getStorage("practice"),
                active_tab: activeTab
            }
            if (isSaveMode) {
                item = {
                    ...item,
                    action: 'add',
                    isClosed: isCloseMode
                }
                setNewAdded(true);
            }
            props.addRemoveFromSelectedTab(item);
        }
    }
    function hideAlertModal() {
        setOpenAlertModal(false);
    }

    function setClaimDateOfService(DOSFromDate, DOSToDate) {
        // first function call from date(dos) change(procedure state change) on each procdure
        // second function call from filtering procedure on save. both are required.
        let fromDate;
        let toDate;

        if (DOSFromDate) {
            if (DOSFromDate.length === 10) {
                DOSFromDate = DOSFromDate + " 00:00:00"
            }
            fromDate = new Date(DOSFromDate);
        } else {
            fromDate = null;
        }
        if (fromDate && DOSToDate) {
            if (DOSToDate.length === 10) {
                DOSToDate = DOSToDate + " 00:00:00"
            }
            toDate = (new Date(DOSToDate));
        } else {
            toDate = null;
        }
        // need to ask ashker which one is proper method 001 - start
        if (saveMode !== 'Save' && fromDate && DOSToDate) {
            dispatch(updateBasicData({ name: "service_from_date", value: fromDate && format(fromDate, 'yyyy-MM-dd'), claimPK: claimInfoDetails?.id }))
            dispatch(updateBasicData({ name: "service_to_date", value: toDate && format(toDate, 'yyyy-MM-dd'), claimPK: claimInfoDetails?.id }))
        }
        else if (fromDate && DOSToDate) {
            dispatch(updateBasicSaveData({ name: "service_from_date", value: fromDate && format(fromDate, 'yyyy-MM-dd') }))
            dispatch(updateBasicSaveData({ name: "service_to_date", value: toDate && format(toDate, 'yyyy-MM-dd') }))
        }
    }

    function exportPaperClaimForm(e, claimPK, withHcfa) {
        if (claimPK) {
            let query = 'claim_pks=' + claimPK + '&with_hcfa=' + withHcfa;
            const result = service.GetPaperClaimFormPDF(query);
            result.then(response => {
                response.headers["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";
                const fileURL = URL.createObjectURL(response.data);
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL + '#filename=' + response.headers['content-disposition'];
            })
        }
    }

    function remShowExport() {
        document.getElementById("export-dropdown").classList.remove('show');
        document.getElementById("export-menu").classList.remove('show');
        document.getElementById("export-dropdown").classList.remove('active');
        document.getElementById("export-menu").classList.remove('active');
    }

    function onShowExport() {
        document.getElementById("export-dropdown").classList.toggle('show');
        document.getElementById("export-menu").classList.toggle('show');
    }

    function claimPostPayment() {
        history.push("/payment/post_payments");
    }

    function redirectToClaimLogs() {
        if (props.claimPK) {
            setStorage('claimPK', props.claimPK);
        }
        const openClaimLogWindow = window.open(ROUTE_CLAIM_ACTION_LOGS);
        // passing claim info to header and user-logs components to dynamically show the page header as respective claim# and patient name 
        openClaimLogWindow.claimInfo = {
            "claim_ID": professionalClaimVariable.id,
            "patient_Name": professionalClaimVariable.patient_name,
            "custom_claimId": Number(professionalClaimVariable.custom_claim_id)
        }

       
    }

    if (gotoLedger) {
        return (
            <Redirect to={{
                pathname: ROUTE_PATIENTS_LEDGER,
                state: { patientPK: patientPK },
            }} />
        );
    }


    function redirectToPatientLedger() {
        setGotoLedger(true);
    }

    function onHandleNoteTypeChange(e) {
        let value = e.target.value;
        setClaimNotesTextType(value);
    }
    function onHandleNoteTextChange(e) {
        let value = e.target.value;
        setClaimNotesText(value);
    }

    function checkCptsAndDosExistsInProcedure(procedureRows) {
        let errors = [];
        if (procedureRows.length === 0 && saveMode === "Save") {
            errors.push(1)
            return { errors }
        }

        procedureRows.forEach((procedure, index) => {
            if (!(procedure.dos_from && procedure.dos_to && procedure.cpt_hcpcs)) {
                errors.push(index + 1);
            }
        });

        return { errors };
    }
    return (
        < >
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <Form id="NewClaim" autoComplete="off" className="basic-info-padding">
                {/* <button type="button" onClick={filteringProcedureData}>heloo</button> */}
                {props.claimPK &&
                    <div className="row mb-4 padding-left25">
                        <div className="dropdown" id="export-dropdown">
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="print-claim">{i18n.t('searchClaims.professionalClaims.claimPrint')}
                                </Tooltip>}>
                                <Link to="#" className={props.claimPK ? "cursor-pointer dropdown-toggle" : ""} data-toggle="dropdown" onFocusOut={remShowExport} onBlur={remShowExport} onClick={props.claimPK ? onShowExport : ""}>
                                    <i className="fa fa-print navBarIcons" style={{ color: "#005cb9", fontSize: "13px" }}></i>
                                    {/*<i className={props.claimPK? "fa fa-print cursor-pointer dropdown-toggle" : "fa fa-print"} style={{ color: "#005cb9", fontSize: "18px" }} data-toggle="dropdown" onFocusOut={remShowExport} onClick={props.claimPK? onShowExport: ""}></i>*/}
                                </Link>
                            </OverlayTrigger>
                            <div className="">
                                <ul className="dropdown-menu dropdown-menu-right download-dropdown-right" style={{ minWidth: "150px !important", top: "-15px" }} id="export-menu">
                                    <li className="download-dropdown-menu-item">
                                        <Link to="#" onMouseDown={(e) => exportPaperClaimForm(e, props.claimPK, 'yes')} className="text-dec-none" >{i18n.t('commons.exportWithHCFA')}</Link>
                                    </li>
                                    <li className="download-dropdown-menu-item">
                                        <Link to="#" onMouseDown={(e) => exportPaperClaimForm(e, props.claimPK, 'no')} className="text-dec-none">{i18n.t('commons.exportWithoutHCFA')}</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {checkPermission(permission_key_values_claim.audit_logs_module_view) &&
                            <div className="" >
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="link-logs">{i18n.t('searchClaims.professionalClaims.claimHistory')}
                                    </Tooltip>}>
                                    <Link to='#'>
                                        <i className="fa fa-history navBarIcons" style={{ color: "#005cb9", fontSize: "13px" }} onClick={props.claimPK ? redirectToClaimLogs : ""} aria-hidden="true"></i>
                                    </Link>
                                </OverlayTrigger>
                            </div>
                        }
                        {checkPermission(permission_key_values_claim.patient_ledger_sub_module_view) &&
                            <div className="pl-2" >
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="link-logs">{i18n.t('searchClaims.professionalClaims.patientLedger')}
                                    </Tooltip>}>
                                    <Link to='#'>
                                        <i className="fa fa-file navBarIcons" style={{ color: "#005cb9", fontSize: "13px" }} onClick={props.claimPK ? redirectToPatientLedger : ""} aria-hidden="true"></i>
                                    </Link>
                                </OverlayTrigger>
                            </div>
                        }
                        {checkPermission(permission_key_values_claim.post_payments_sub_module_view) &&
                            <div className="pl-2" >
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="link-payments" >{i18n.t('searchClaims.professionalClaims.claimPayments')}
                                    </Tooltip>}>
                                    <i className="fas fa-dollar-sign navBarIcons padding-left10 padding-right10 cursor-pointer" style={{ color: "#005cb9", fontSize: '13px' }} onClick={claimPostPayment} />
                                </OverlayTrigger>
                            </div>
                        }
                        <div className="pl-2" >
                            <i className="fas fa-ellipsis-h navBarIcons" style={{ color: "#005cb9", fontSize: '13px' }} />
                        </div>
                    </div>
                }
                {
                    saveMode != 'Save' &&
                    <div className="">
                        {!professionalClaimVariable.claim_editable && checkPermission(permission_key_values_claim.claims_search_claims_add, permission_key_values_claim.claims_search_claims_modify) &&
                            <div className="padding-left15 margin-bottom15" >
                                <div className={'warning_lock'}>
                                    <span style={{ fontWeight: 500 }}> {i18n.t("commons.locking")}{" "}: {" "}
                                        <span style={{ fontWeight: 500 }} >
                                            {professionalClaimVariable.locked_by_name}
                                        </span>
                                    </span>
                                    {checkPermission(permission_key_values.administration_page_locks_view) && (
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="link-logs">{i18n.t("searchClaims.professionalClaims.routeToPatientLock")}
                                            </Tooltip>}>
                                            <IconButton
                                                onClick={() => {
                                                    history.push(ROUTE_PAGE_LOCKS);
                                                }}>
                                                <LaunchIcon style={{ fontSize: 'small' }} />
                                            </IconButton>
                                        </OverlayTrigger>
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                }

                    {props.claimPK && billingInfoDataLoaded && /*  //Warning Message changes --- start*/
                    <div className="margin-left15">
                            <WarningPopUp notifyDescription={i18n.t("warning.incompleteData")}
                                formWarningData={formWaringData} formWarningStatus={formWarningStatuses}
                            />
                        </div>
                        /*//Warning Message changes --- end*/}

                <div className="row margin-left1">
                    {!props.claimPK ?

                        <>
                            <PatientDropDownSearch setSelectPatient={setSelectPatient} setPatientPK={setPatientPK}
                                patientSelected={patientSelected} setPatientSelected={setPatientSelected} inputClass="padding-top25"
                                orPaddingTop="55px" patientAdvSearchData={patientAdvSearchData} setPatientRemoved={setPatientRemoved}></PatientDropDownSearch>
                            <div className="margin-top50 col-4">
                                <RightSidePanel title={i18n.t("commons.advancedSearch")} onclickLabel={i18n.t("commons.advancedSearch")} >
                                    <PatientsAdvancedSearch setSelectPatient={setSelectPatient} setPatientPK={setPatientPK}
                                        setPatientSelected={setPatientSelected} setPatientAdvSearchData={setPatientAdvSearchData}>
                                    </PatientsAdvancedSearch>
                                </RightSidePanel>
                            </div>
                        </>
                        :
                        ""

                    }
                </div>
                <div className='row margin-left1 margin-top20'>
                    {props.claimPK ?
                        <div className="col-3">
                            <TextInput name="patient_name" value={professionalClaimVariable.patient_name} disabled={true} label={i18n.t("searchClaims.professionalClaims.selectPatient")} />
                        </div>
                        : ""}
                    {props.claimPK ?
                        <div className="col-3 pl-0">
                            <TextInput type="text" name="claimId" label={i18n.t("searchClaims.professionalClaims.claimId")} disabled="disabled"
                                value={professionalClaimVariable.custom_claim_id} onValueChange={onHandleChange}
                            />
                        </div>
                        :
                        " "
                    }
                    <div className={!props.claimPK ? "col-3" : "col-3 pl-0"}>
                        <TextInput type="text" name="reference_number" label={i18n.t("searchClaims.professionalClaims.internalReference")}
                            value={professionalClaimVariable.reference_number} onValueChange={onHandleChange}
                        />
                    </div>

                    <div className="col-3 pl-0">
                        <CalendarInput name="posted_date" label={i18n.t("searchClaims.professionalClaims.createdDate")} disabled={true}
                            selected={professionalClaimVariable.posted_date} maxDate={new Date()} onValueChange={(e) => onHandleNewClaimDate(e, "posted_date")}
                        />
                    </div>
                </div>
                <div className="row margin-left1">
                    <div className="col-3">
                        <SelectInput data={claimStatusList} name="claim_status" value={saveMode !== 'Save' ? claimInfoDetails?.claim_status : commonData?.claim_status} onValueChange={onHandleChange} label={i18n.t("searchClaims.professionalClaims.claimStatus")} />
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput data={claimSubStatusList} name="claim_sub_status" value={saveMode !== 'Save' ? claimInfoDetails?.claim_sub_status : commonData?.claim_sub_status} onValueChange={onHandleChange} label={i18n.t("searchClaims.professionalClaims.claimSubStatus")} />
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput data={AUTHORIZATION_REFERRAL} name="authorization_referral" value={saveMode !== 'Save' ? claimInfoDetails?.authorization_referral : commonData?.authorization_referral} onValueChange={onHandleChange} label={i18n.t("searchClaims.professionalClaims.authorizatonReferral")} />
                    </div>
                    {hide ? <div className="col-3 pl-0">
                        <SelectInput data={identifierData} required={professionalClaimVariable.authorization_referral ? true : false} name="identifier" value={professionalClaimVariable.identifier} onValueChange={onHandleChange} label={i18n.t("searchClaims.professionalClaims.identifier")} />
                    </div> : ""}
                </div>
                {/* TO CREATE NEW CLAIM, BILLING INFO SAVE BUTTON IS USING NOW */}
                {props.activeTab === 'editClaims' + props.claimPK &&
                    <TabContext value={activeTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={onTabChange} aria-label="lab API tabs example" style={{ marginLeft: 20, borderBottom: '1px solid #E0E0E0', marginTop: 35 }}>
                                <Tab label={i18n.t("searchClaims.professionalClaims.billingInfo")} value={TAB1} className='tab' />
                                <Tab label={i18n.t("searchClaims.professionalClaims.serviceLines")} value={TAB2} className='tab' />
                                <Tab label={i18n.t("searchClaims.professionalClaims.additionalClaimInfo")} value={TAB3} className='tab' />
                                <Tab label={i18n.t("searchClaims.professionalClaims.notes")} value={TAB4} className='tab' />
                            </TabList>
                        </Box>
                        <TabPanel value={TAB1} className='searchBoxTab'>
                            <BillingInfo setTertiaryInsuranceList={setTertiaryInsuranceList} setSecondaryInsuranceList={setSecondaryInsuranceList} setPrimaryInsuranceList={setPrimaryInsuranceList} setBillingInfo={setBillingInfo} setFormWarningData={setFormWarningData}
                                formWarningData={formWarningData} formWarningStatus={formWarningStatus}
                                setFormWarningStatus={setFormWarningStatus} billingInfoDbData={billingInfoDbData} setIsSaved={setIsSaved}
                                setBillingInfoDbData={setBillingInfoDbData} billing_info_pk={claimInfoDetails?.pks?.billing_info_pk} isSaved={isSaved}
                                patientPK={saveMode === 'Save' ? commonData?.patient : claimInfoDetails?.patient} practicePK={practicePK} claimPK={props.claimPK}
                                newClaimAction={props.newClaimAction} setBillingInfoDataLoaded={setBillingInfoDataLoaded} billingInfoDataLoaded={billingInfoDataLoaded}
                                setServiceLocationId={setServiceLocationId} setProviderChanged={setProviderChanged}
                            />
                        </TabPanel>
                        {(props.activeSubTab === TAB2 || props.activeSubTab === undefined) &&
                            <TabPanel value={TAB2} className='searchBoxTab'>
                                <ServiceLines claimPK={props.claimPK} patientPK={saveMode === 'Save' ? commonData?.patient : claimInfoDetails?.patient} setFormWarningStatus={setFormWarningStatus} setFormWarningData={setFormWarningData}
                                    formWarningData={formWarningData} formWarningStatus={formWarningStatus} procedure={procedure} procedureRows={procedureRows}
                                    setProcedureRows={setProcedureRows} setDiagnosises={setDiagnosises}
                                    dateOfService={professionalClaimVariable.service_from_date} to={professionalClaimVariable.service_to_date}
                                    diagnosises_pk={claimInfoDetails?.pks?.diagnosises_pk}
                                    refreshProcedure={refreshProcedure} setRefreshProcedure={setRefreshProcedure} setDiagnosisDbData={setDiagnosisDbData}
                                    setCommonDXPointers={setCommonDXPointers} claimInactive={professionalClaimVariable.claim_inactive} claimDbActive={claimDbActive}
                                    onInactiveClaim={onInactiveClaim} onSaveFormData={onSaveFormData} claimEditable={professionalClaimVariable.claim_editable}
                                    setDiagnosisesValidated={setDiagnosisesValidated}
                                    setCheckForDuplicate={setCheckForDuplicate}
                                    setModifiersValidated={setModifiersValidated}
                                    modifiersValidated={modifiersValidated} addNewRowClicked={addNewRowClicked} setAddNewRowClicked={setAddNewRowClicked}
                                    facilityPOS={facilityPOS} setIsSaved={setIsSaved} isSaved={isSaved} isClosed={isCloseMode}
                                    serviceLocationId={serviceLocationId}
                                    patientDeathDate={patientDeathDate}
                                    setIsProcedureChangeFlag={setIsProcedureChangeFlag}
                                />
                            </TabPanel>
                        }
                        {(props.activeSubTab === TAB3 || props.activeSubTab === undefined) &&
                            <TabPanel value={TAB3} className='searchBoxTab'>
                                <AdditionalClaimInfo patientPK={saveMode === 'Save' ? commonData?.patient : claimInfoDetails?.patient} claimPK={props.claimPK} setAdditionalInfoData={setAdditionalInfoData}
                                    setAdditionalInfoDataDb={setAdditionalInfoDataDb} additionalClaim_pks={claimInfoDetails?.pks?.additional_claim_info_pk} dataFromDbExist={dataFromDbExist}
                                    setFormWarningStatus={setFormWarningStatus} setFormWarningData={setFormWarningData} formWarningData={formWarningData}
                                    formWarningStatus={formWarningStatus} dos={professionalClaimVariable.service_from_date}
                                    setAccidentNoId={setAccidentNoId} accidentNoId={accidentNoId} setIsSaved={setIsSaved} isSaved={isSaved} isClosed={isCloseMode} patientDeathDate={patientDeathDate}/>
                            </TabPanel>
                        }
                        {(props.activeSubTab === TAB4 || props.activeSubTab === undefined) &&
                            <TabPanel value={TAB4} className='searchBoxTab'>
                                {props.activeTab != "editClaims" && professionalClaimVariable.claim_editable && checkPermission(permission_key_values_claim.claims_search_claims_add) ?
                                    <div className="alignRight padding-bottom10 padding-top10">
                                        <CommonButton icon="add" variant="contained" onClick={addNewClaimNotes} label={i18n.t("buttons.addNew")} />
                                    </div> : null}
                                <table className="table margin-top20">
                                    <thead>
                                        <tr>
                                            <th className='width-15-perc'>{i18n.t("searchClaims.professionalClaims.created_on")}</th>
                                            <th className='width-10-perc'>{i18n.t("searchClaims.professionalClaims.noteType")}</th>
                                            <th className='width-50-perc'>{i18n.t("searchClaims.professionalClaims.note")}</th>
                                            <th className='width-15-perc'>{i18n.t("searchClaims.professionalClaims.createdBy")}</th>
                                            {professionalClaimVariable.claim_editable && (<th className='width-10-perc'>{i18n.t("searchClaims.professionalClaims.action")}</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {claimNotesList && claimNotesList.length === 0 &&
                                            <tr>
                                                <td align="center" colSpan={5}>{i18n.t("commons.noRecords")}</td>
                                            </tr>
                                        }
                                        {claimNotesList && claimNotesList.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td align='center'>{format(new Date(item.created_on), DEFAULT_DATE_FORMAT)}</td>
                                                    <td>{item.notes_type_name}</td>
                                                    <td style={{
                                                        whiteSpace: "pre-line",
                                                        wordWrap: 'break-word',
                                                        overflowWrap: 'break-word',
                                                        wordBreak:'break-word'
                                                    }}>
                                                        {item.notes}
                                                    </td>
                                                    <td>{item.created_by_name}</td>
                                                    {professionalClaimVariable.claim_editable && (
                                                        <td align='center'>
                                                            <div className="dropdown" id={'dropdown-' + item.id}>
                                                                <button
                                                                    id={'dropdownMenuButton-' + item.id}
                                                                    className="dropdown-toggle"
                                                                    type="button"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    onClick={(e) => onShowActionButtonsClaim(e, item.id)}
                                                                    onBlur={(e) => onHideActionButtonsClaim(e, item.id)}
                                                                >
                                                                    <img id="test" src={actionImg} alt="Action" />
                                                                </button>
                                                                <div className="dropdown-menu" id={'dropdown-menu-' + item.id}>
                                                                    <Link
                                                                        className="dropdown-item edit"
                                                                        to="#"
                                                                        onMouseDown={() => onEditClaimNote(item.id)}
                                                                    >
                                                                        {'Edit'}
                                                                    </Link>
                                                                    <Link
                                                                        className="dropdown-item edit"
                                                                        to="#"
                                                                        onMouseDown={() => onDeleteClaimNote(item.id)}
                                                                    >
                                                                        {'Delete'}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                </table>
                                <div className="clear-b-scroll-sector">&nbsp;</div>
                            </TabPanel>
                        }
                        {professionalClaimVariable.claim_editable && activeTab === TAB1 &&
                            <div className='d-flex justify-content-between'>
                                <div className="d-flex">
                                    {checkPermission(permission_key_values_claim.search_claims_sub_module_modify) && props.claimPK && <>
                                        <div className="max-width-3 padding-left15 form-group">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id={"claim_inactive" + props.claimPK}
                                                    checked={claimInfoDetails.claim_inactive} name="claim_inactive" onChange={onHandleChange}
                                                />
                                                <label className="checkbox top-14" htmlFor={"claim_inactive" + props.claimPK}></label>
                                            </div>
                                        </div>
                                        <div className="padding-top5 padding-left20">
                                            <label className="padding-top5 margin-top-3px">{i18n.t('patientPages.patientInfo.inactive')}</label>
                                        </div>
                                    </>}
                                </div>
                                {checkPermission(permission_key_values_claim.claims_search_claims_add, permission_key_values_claim.claims_search_claims_add) &&
                                    <div className="justify-right padding-right20 padding-top5">
                                        <button type="button" className="btn btn-primary-blue mr-2 btn-common-height35 width-75"
                                            onClick={professionalClaimVariable.claim_inactive && !claimDbActive ? (e) => onInactiveClaim(e) : (e) => onSaveFormData(e)}
                                        >
                                            {i18n.t("buttons.save")}
                                        </button>
                                        <button type="button" className="btn btn-primary-blue btn-common-height35 width-75"
                                            onClick={professionalClaimVariable.claim_inactive && !claimDbActive ? (e) => onInactiveClaim(e) : (e) => onSaveFormData(e, true)}
                                        >
                                            {i18n.t("buttons.saveClose")}
                                        </button>
                                    </div>}
                            </div>
                        }
                        {checkPermission(permission_key_values_claim.search_claims_sub_module_modify, permission_key_values_claim.claims_search_claims_add) && professionalClaimVariable.claim_editable && (activeTab === TAB3) &&
                            <div className="justify-right padding-right15 padding-top5">
                                <button type="button" className="btn btn-primary-blue mr-2 btn-common-height35 width-75"
                                    onClick={professionalClaimVariable.claim_inactive && !claimDbActive ? (e) => onInactiveClaim(e) : (e) => onSaveFormData(e)}
                                >
                                    {i18n.t("buttons.save")}
                                </button>
                                <button type="button" className="btn btn-primary-blue btn-common-height35 width-75"
                                    onClick={professionalClaimVariable.claim_inactive && !claimDbActive ? (e) => onInactiveClaim(e) : (e) => onSaveFormData(e, true)}
                                >
                                    {i18n.t("buttons.saveClose")}
                                </button>
                            </div>
                        }
                    </TabContext>}

            </Form >

            <CustomizedSmallDialogs showModal={openAlertModal} setShowModalWindow={setOpenAlertModal} alertOK={hideAlertModal} header={i18n.t("commons.alert")} type="alert" onHide={hideAlertModal}
                resetForm={hideAlertModal}>
                {alertMsg}
            </CustomizedSmallDialogs>
            <CustomizedDialogs showModal={showModalWindow} type="formSubmit" onSaveFormData={onSaveClaimNotes} setShowModalWindow={setShowModalWindow} header={header} resetForm={resetForm} onHide={onHide} >
                <Form id="formSubmit" autoComplete="off" >
                    <div className="col-6 pl-0 padding-top5">
                        <div className="form-group">
                            <SelectInput disabled={true} required={true} data={notesTypeList} name="claimNotesTextType" value={claimNotesTextType}
                                label={i18n.t("searchClaims.professionalClaims.noteType")} setValue={setClaimNotesTextType}
                                onValueChange={(e) => onHandleNoteTypeChange(e)} />
                        </div>
                    </div>
                    <div className="">
                        <div className="form-group padding-top10">
                            <TextAreaInput required={true} rows={3} name="claimNotesText" value={claimNotesText}
                                label={i18n.t("searchClaims.professionalClaims.labelNote")}
                                onValueChange={(e) => onHandleNoteTextChange(e)} />
                        </div>
                    </div>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs showModal={deleteClaimNoteModal} setShowModalWindow={setDeleteClaimNoteModal} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={DeleteClaimNote} resetForm={resetForm} onHide={onHide}>
                {i18n.t("searchClaims.professionalClaims.ClaimNoteDeleteConfirmation") + " '" + deleteClaimNoteMessage + "'?"}
            </CustomizedSmallDialogs>
            <CustomizedSmallDialogs showModal={inactiveClaimModal} setShowModalWindow={setInactiveClaimModal} header={i18n.t("commons.confrimInactiveHeader")} type="yes" onHide={onHide}
                inactveItem={onSaveFormData} resetForm={onHide} buttonStyle={'danger'}>
                {i18n.t("commons.confrimInactive") + " claim?"}
            </CustomizedSmallDialogs>
            <CustomizedSmallDialogs showModal={activeClaimModal} setShowModalWindow={setActiveClaimModal} header={i18n.t("commons.confrimActiveHeader")} type="yes" onHide={onHide}
                inactveItem={ActivateClaim} resetForm={onHide} buttonStyle={'primary-blue'}>
                {i18n.t("commons.confrimActive") + " Claim?"}
            </CustomizedSmallDialogs>
            <CustomizedSmallDialogs showModal={inactiveClaimAlertModal} setShowModalWindow={setInactiveClaimAlertModal} alertOK={onAlertOk} header={i18n.t("commons.alert")} type="alert" onHide={onHide}
                resetForm={onHide}>
                {i18n.t("errorMessages.claim_inactive")}
            </CustomizedSmallDialogs>
            <CustomizedSmallDialogs showModal={saveConfirmation} setShowModalWindow={setSaveConfirmation} alertOK={onSaveHide} header={i18n.t("commons.warnings")} type="alert" onHide={onSaveHide}
                resetForm={onSaveHide}>
                <div className="display-grid">{ReactHtmlParser(warningMessage)}</div>
            </CustomizedSmallDialogs>
            <CustomizedSmallDialogs showModal={saveCloseConfirmation} setShowModalWindow={setSaveCloseConfirmation} inactveItem={onSaveCloseHide} header={i18n.t("commons.warnings")} type="yes" saveType={true} onHide={onSaveHide}
                resetForm={onSaveHide}>
                <div className="display-grid">{ReactHtmlParser(warningMessage)}</div>
                <p className="bold-font margin-top-16">{i18n.t('commons.confirmClose')}</p>
            </CustomizedSmallDialogs>
        </>
    )
}

export default ProfessionalClaims