import React,{useState,useEffect,useContext} from 'react';
import {Form} from 'react-bootstrap';
import { useLocation  } from 'react-router-dom';

import service from './service';
import { AppointmentStatusTableObject, AppointmentStatusTableBodyOnly } from './AppointmentStatusTable';

import i18n from '../../../utilities/i18n';
import { getStorage } from '../../../utilities/browserStorage'; 
import { checkPermission } from '../../../utilities/commonUtilities';
import {ADD_SUCCESS,ADD_ERROR,UPDATE_SUCCESS,DELETE_SUCCESS} from '../../../utilities/labelConfigs';
import { permission_key_values_practice_dictionaries, super_admin_privileges, super_admin_permission_key_value } from '../../../utilities/permissions';
import {ROUTE_SUPER_DICTIONARIES, ROUTE_PRACTICE_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE, ROUTE_APPOINTMENT_STATUSES} from '../../../utilities/staticConfigs';

import Notify from '../../commons/notify';
import Table from '../../commons/Table/Table'; 
import TextInput from '../../commons/input/input';
import Pagination from '../../commons/pagination';
import BackArrowWithLabel from "../../commons/Back"; 

import LoadingContext from '../../../container/loadingContext';

import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import CommonButton from '../../commons/Buttons';



const AppointmentList = (props) =>{
    const isAdminModule = getStorage('isAdminModule');
    const location = useLocation();
    const practicePK = getStorage("practice");
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [modalHeader, setModalHeader] = useState(i18n.t('dictionariesPages.dictAppointmentStatus.addHeader'));
    const [appointmentStatus, setAppointmentStatus] = useState('');
    const [deleteAppointmentName, setDeleteAppointmentName] = useState('');
    const [deleteAppointmentId, setDeleteAppointmentId] = useState('');
    const [editAppointmentId, setEditAppointmentId] = useState('');
    const [appointmentStatusList, setAppointmentStatusList] = useState([]);
    const [form_error, setFormError] = useState({'appointmentStatus':''});
    const [editForm, setEditForm] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    //start ==== Alert message properties
    const [showNotify,setShowNotify] = useState('hide');
    const [notifyDescription,setNotifyDescription] = useState('');
    const [notifyType,setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);
    const [permission, setPermission] = useState(false);


    function showNotifyWindow(action,type,desc,age=3000){
        if(action == 'show'){
            setTimeout(()=> {
                setShowNotify('hide');
            },age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    //End ====

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex+1-PAGING_END_INDEX;
        setActivePage(previousPage);

        if(startIndex!==0){
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getAppointmentData(DEFAULT_PAGING_SIZE, previousPage, searchValue);          
    }

    function onPageUp(e){
        let page = Number(e.target.id)
        setActivePage(page);
        getAppointmentData(DEFAULT_PAGING_SIZE, page, searchValue); 
    }

    function onPageNext() {
        let nextPage = startIndex+1+PAGING_END_INDEX;
        if(endIndex===totalPage || totalPage<=PAGING_END_INDEX){
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        }else{
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getAppointmentData(DEFAULT_PAGING_SIZE, nextPage, searchValue);
    }
    //Pagination ends

    function addNew(){
        if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        setModalHeader(i18n.t('dictionariesPages.dictAppointmentStatus.addHeader'));
        setShowModalWindow(true);
    }

    function onHide(){
        setEditForm(false);
    }

    function onDeleteHide(){
        setDeleteAppointmentName('');
        setDeleteAppointmentId('');
        setShowDeleteModalWindow(false);
    }

    function getAppointmentData(pageSize, page, searchString) {
        setShowLoadingBar(true);
        let result =isAdminModule === "true"? service.ListAppointmentStatuses(pageSize, page, isAdminModule, "", searchString): service.ListAppointmentStatuses(pageSize,page,isAdminModule, practicePK,searchString);
        result.then(async (response) => {
            if (response.data.results !== undefined) {
                setTotalPage(
                    Math.ceil(response.data.count / response.data.page_size)
                );
            }
            setShowLoadingBar(false);

            let rowArray = getRowData(response.data.results);
            if (rowArray.length > 0) {
                AppointmentStatusTableObject.tableBodyData = rowArray;
            } else {
                AppointmentStatusTableObject.tableBodyData =
                    AppointmentStatusTableBodyOnly;
            }
            setAppointmentStatusList(response.data.results);
        });
    }

    /**listing table data **/
    function getRowData(data) {
        let anArray = [];
        let rowArray = [];
        let newData = Array(data.length).fill(JSON.parse(JSON.stringify(AppointmentStatusTableObject.tableBodyData[0])));
        newData.map((row, rowIndex) => {
            row.map((col) => {
                let colObject = {};
                    colObject = {
                        ...col, 'value': data[rowIndex][col.name],
                        "id": data[rowIndex].id,
                    }
                anArray.push(colObject)
            })
            rowArray.push(anArray)
            anArray = [];
        });
        return rowArray;
    }

    useEffect(() => {
        getAppointmentData(DEFAULT_PAGING_SIZE, activePage);
        if(location.pathname === ROUTE_APPOINTMENT_STATUSES){
            setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add))
        } else {
            setPermission(checkPermission(permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_modify,
                permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_add,
                super_admin_privileges.super_admin_full_privilege))
        }
    }, []);

    function dropDownFunction(id, name) {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        if (name.toLowerCase() == 'edit') {
            onEditAppointmentStatus(Number(id));
        } else if (name.toLowerCase() == 'delete') {
            onDeleteAppointmentStatus(Number(id));
        }
    }

    function onEditAppointmentStatus(appointmentId){
        setShowLoadingBar(true);
        let data = isAdminModule === 'true' ? service.GetAppointmentStatus(appointmentId) : service.GetAppointmentStatus(appointmentId, practicePK);
        data.then(response => {
            setModalHeader(i18n.t('dictionariesPages.dictAppointmentStatus.editHeader'));
            setShowLoadingBar(false);
            setShowModalWindow(true);
            setAppointmentStatus(response.data.name);
            setEditForm(true);
            setEditAppointmentId(appointmentId);
        });
    }

    function onDeleteAppointmentStatus(appointmentId){
        appointmentStatusList.map((item) => {
            if(item.id == parseInt(appointmentId)){
                setDeleteAppointmentName(item.name);
                setDeleteAppointmentId(item.id);
                setShowDeleteModalWindow(true);
            }
        });
    }

    function onDeleteAction(){
        setShowLoadingBar(true);
        let data = isAdminModule === 'true' ? service.DeleteAppointmentStatus(deleteAppointmentId) : service.DeleteAppointmentStatus(deleteAppointmentId, practicePK);
        data.then(() => {
            setShowLoadingBar(false);
            setDeleteAppointmentName('');
            setDeleteAppointmentId('');
            setShowDeleteModalWindow(false);
            showNotifyWindow('show','success',DELETE_SUCCESS);
            getAppointmentData(DEFAULT_PAGING_SIZE, activePage);
        });
        setSearchValue("");
    }

    function resetForm(){
        setAppointmentStatus('');
        setEditForm(false);
        setFormError({
              ...form_error,
              ['appointmentStatus']: ''
         });

    }
     const handleKeyDown = (e) => {
         if (e.key === "Enter" && searchValue) {
             onSearch(e, searchValue);
         }
     };
      function onSearch(e, searchString) {
          e.preventDefault();
          setActivePage(1);
          setStartIndex(0);
          getAppointmentData(DEFAULT_PAGING_SIZE, 1, searchString);
      }

      const onClose = () => {
          setSearchValue("");
          setActivePage(1);
          setStartIndex(0);
          getAppointmentData(DEFAULT_PAGING_SIZE, 1);
      };
    function onSaveFormData(e){
        e.preventDefault();
        if(!appointmentStatus.trim()){
            setFormError({
              ...form_error,
              ['appointmentStatus']: 'error'
            });
        }else{
            setShowLoadingBar(true);
            let data = isAdminModule === 'true' ? {name: appointmentStatus} : {practice: practicePK, name: appointmentStatus}
            let result =editForm ? service.UpdateAppointmentStatus(editAppointmentId, data) : service.AddAppointmentStatus(data)
            result.then(response => {
                setShowLoadingBar(false);
                if(response.status == 201 || response.status == 200){
                    if(response.status == 201)
                        showNotifyWindow('show','success',ADD_SUCCESS);
                    else
                        showNotifyWindow('show','success',UPDATE_SUCCESS);
                    getAppointmentData(DEFAULT_PAGING_SIZE, activePage);
                    resetForm();
                    setShowModalWindow(false);
                }else if(response.status === 400 && response.data.record_already_exists !== undefined)
                    showNotifyWindow('show','error',i18n.t('errorMessages.record_exists'));
                else
                    showNotifyWindow('show','error',ADD_ERROR);
            });
            setSearchValue(""); 
        }
    }
    function onHandleChange(e){
        const {name}  = e.target;
        let value = e.target.value;
        let trimmedValue = '';
        if(e.target.type==="text"){
            trimmedValue = value; 
        }
        if (name == "searchValue") {
            setSearchValue(trimmedValue);
            return;
        }
        if(!trimmedValue){
            setFormError({
              ...form_error,
              [name]: 'error'
            });
        }else{
            setFormError({
              ...form_error,
              [name]: ''
            });
        }
        setAppointmentStatus(trimmedValue);
    }    

    function backToDictionaryPage(){
        if (isAdminModule === 'true')
            props.history.push(ROUTE_SUPER_DICTIONARIES);
        else
            props.history.push(ROUTE_PRACTICE_DICTIONARIES);
    }
    
    return (
        <React.Fragment>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className="col-md-8">
                <div className="box box-content-white mb-3">
                    <div className="row mr-0 mb-2">
                        <div className="col pl-4 mb-1">
                            <div className={"dataTables_filter"}>
                                <div
                                    className="link dictionaries-back"
                                    onClick={backToDictionaryPage}>
                                    <BackArrowWithLabel
                                        label={i18n.t(
                                            "dictionariesPages.gotoDictionaries"
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-content ">
                        <div className="common-search-wrapper-style">
                            <div className="input-content-box">
                                <div className="form-group padding-top15 relative">
                                    <TextInput
                                        type="text"
                                        id="searchValue"
                                        name="searchValue"
                                        value={searchValue}
                                        onValueChange={onHandleChange}
                                        label={i18n.t(
                                            "dictionariesPages.dictAppointmentStatus.lblAppointmentStatus"
                                        )}
                                        onKeyDown={handleKeyDown}
                                        labelClassName={"margin-bottom0"}
                                        clearButton={
                                            typeof searchValue == "string" &&
                                            searchValue.length !== 0
                                        }
                                        clearInput={onClose}
                                    />
                                </div>
                            </div>
                            <div className="input-content-box padding-top15">
                                <div className="margin-top18">
                                    <CommonButton
                                        variant="contained"
                                        label="Search"
                                        onClick={(e) =>
                                            onSearch(e, searchValue)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box box-content-white">
                    <div className="box-content">
                        <div className="alignRight margin-bottom3">
                            <CommonButton
                                variant="contained"
                                onClick={addNew}
                                label={i18n.t("buttons.addNew")}
                            />
                        </div>

                        <div className="table-responsive">
                            <Table
                                tableObject={AppointmentStatusTableObject}
                                dropDownFunction={dropDownFunction}
                            />
                        </div>
                        <div className="mt-2 mb-2">
                            <Pagination
                                totalPage={totalPage}
                                activePage={activePage}
                                startIndex={startIndex}
                                endIndex={endIndex}
                                onPagePrevious={onPagePrevious}
                                onPageUp={onPageUp}
                                onPageNext={onPageNext}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <CustomizedDialogs
                showModal={showModalWindow}
                type="save"
                header={modalHeader}
                setShowModalWindow={setShowModalWindow}
                resetForm={resetForm}
                onHide={onHide}>
                <Form
                    id="form_dataEntry"
                    autoComplete="off"
                    onSubmit={(e) => onSaveFormData(e)}
                    encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group padding-top15">
                                    <TextInput
                                        type="text"
                                        id="appointmentStatus"
                                        name="appointmentStatus"
                                        label={i18n.t(
                                            "dictionariesPages.dictAppointmentStatus.lblAppointmentStatus"
                                        )}
                                        onValueChange={onHandleChange}
                                        defaultClassName={
                                            form_error.appointmentStatus
                                                ? "input-error"
                                                : ""
                                        }
                                        value={appointmentStatus}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs
                showModal={showDeleteModalWindow}
                header="Confirm Delete"
                type="delete"
                deleteItem={onDeleteAction}
                resetForm={resetForm}
                onHide={onDeleteHide}
                setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t(
                    "dictionariesPages.dictAppointmentStatus.deletConfirmation"
                ) +
                    " '" +
                    deleteAppointmentName +
                    "'?"}
            </CustomizedSmallDialogs>
        </React.Fragment>
    );
}
export default AppointmentList